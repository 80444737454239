/* Add this media query for small screens */
@media (max-width: 576px) {
    .country {
      flex-direction: column; /* Stack the sections vertically */
    }
  
    .right-section {
      order: -1; /* Move the right section (image) above */
      margin-bottom: 20px; /* Add spacing below the image */
    }
  
    .left-section {
      order:1;
      margin-top: 20px; /* Add spacing above the left section */
    }
  }
  
  